import React, {useEffect} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useRecoilValue, useResetRecoilState} from 'recoil'
import {
	exportDataState,
	memberTransactionsOutOfRangeState
} from '../../state/pulse'
import DataAvailabilityWarningOverlay from './components/DataAvailabilityWarningOverlay'
import ValueBandGraph from './components/ValueBandGraph'
import ProtectionPeriodBandGraph from './components/ProtectionPeriodBandGraph'
import RefundPeriodBandGraph from './components/RefundPeriodBandGraph'

const PulseBandsView = () => {
	const memberTransactionsOutOfRange = useRecoilValue(memberTransactionsOutOfRangeState)
	const resetExportDataState = useResetRecoilState(exportDataState)

	useEffect(() => {
		resetExportDataState()
	}, [])

	return (
		<DashboardPageLayout>
			<section className="
				max-w-6xl mb-8 px-4 relative w-full
				lg:mx-auto lg:px-10"
			>
				{!memberTransactionsOutOfRange && (
					<>
						<ValueBandGraph/>
						<ProtectionPeriodBandGraph/>
						<RefundPeriodBandGraph/>
					</>
				)}
				{memberTransactionsOutOfRange && (<DataAvailabilityWarningOverlay/>)}
			</section>
		</DashboardPageLayout>
	)
}

export default PulseBandsView
