import React, {useEffect} from 'react'
import DashboardPageLayout from './components/DashboardPageLayout'
import {useResetRecoilState} from 'recoil'
import {exportDataState} from '../../state/pulse'
import H2 from './components/Common/Headers/H2'
import PageAnalyticsView from './components/PageAnalyticsView'

const PulsePageAnalyticsView = () => {
	const resetExportDataState = useResetRecoilState(exportDataState)

	useEffect(() => {
		resetExportDataState()
	}, [])

	return (
		<DashboardPageLayout>
			<section className="
				max-w-6xl mb-8 px-4 relative w-full
				lg:mx-auto lg:px-10"
			>
				<H2>User History</H2>
				<PageAnalyticsView/>
			</section>
		</DashboardPageLayout>
	)
}

export default PulsePageAnalyticsView
