import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {formatNumber} from '../../../utils/number'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {refundPeriodName} from '../../../types/pulse-data'
import {pulseColorGray} from '../../../types/pulse'

export default function useGetRefundPeriodBandBarChartData() {
	const {getRefundPeriodBandsChartData} = usePulseApi()
	const value = useApiValue(filterState, getRefundPeriodBandsChartData, {})

	const getOptions = () => {
		const options: ChartOptions<'bar'> = {
			indexAxis: 'y',
			plugins: {
				legend: {
					display: true,
					position: 'top'
				},
				tooltip: {
					bodySpacing: 8,
					boxPadding: 4,
					callbacks: {
						label: (tooltip: TooltipItem<'bar'>) => {
							return `${tooltip.dataset.label}: ${formatNumber(tooltip.raw?.toString() || '', 0)}`
						}
					},
					enabled: true,
					mode: 'index',
					padding: 16,
					titleMarginBottom: 8
				}
			},
			scales: {
				x: {
					display: true,
					position: 'top',
					type: 'linear'
				},
				y: {
					display: true,
					grid: {
						display: false
					}
				}
			}
		}

		return options
	}

	const getLabels = () =>
		(value.data?.chartData && value.data.chartData.map(band =>
			refundPeriodName[band.refundPeriod || 0]
		)) || []

	const getDatasets = () => {
		const datasets: ChartDataset<'bar'>[] = []

		if (!value.data?.chartData) {
			return datasets
		}

		const data: number[] = []

		for (let i = 0; i < value.data.chartData.length; i++) {
			const band = value.data.chartData[i]

			data.push(band.refundVolume || 0)
		}

		datasets.push({
			backgroundColor: pulseColorGray,
			data: data,
			label: 'Volume',
			xAxisID: 'x'
		})

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions,
		isLoading: value.isLoading
	}
}
