import {Bar} from 'react-chartjs-2'
import React from 'react'
import {titleSuffixState} from '../../../state/pulse'
import LoadingSpinner from './Common/Spinner/LoadingSpinner'
import {useRecoilValue} from 'recoil'
import H2 from './Common/Headers/H2'
import useGetProtectionPeriodBandBarChartData from '../hooks/useGetProtectionPeriodBandBarChartData'

const ProtectionPeriodBandGraph = () => {
	const chartData = useGetProtectionPeriodBandBarChartData()
	const titleSuffix = useRecoilValue(titleSuffixState)

	return (
		<div className="
			mb-0 space-x-3
			md:mb-4 md:flex"
		>
			<div className="flex-1">
				<H2>Booking Window Bands - {titleSuffix}</H2>
				<LoadingSpinner
					isLoading={chartData.isLoading}
					className="flex items-center justify-center min-h-96 w-full"
				>
					<Bar
						className="max-w-full mb-4"
						data={{
							datasets: chartData.getDatasets(),
							labels: chartData.getLabels()
						}}
						options={chartData.getOptions()}
					/>
				</LoadingSpinner>
			</div>
		</div>
	)
}

export default ProtectionPeriodBandGraph
