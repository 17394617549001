import {ChartDataset, ChartOptions, TooltipItem} from 'chart.js'
import {formatCurrency} from '../../../utils/currency'
import {useRecoilValue} from 'recoil'
import {selectedCoreCurrencyState} from '../../../state/pulse-filters'
import {formatNumber} from '../../../utils/number'
import {usePulseApi} from '../../../hooks/api/pulse'
import {useApiValue} from '../../../hooks/api'
import {filterState} from '../../../state/pulse'
import {pulseColorGray, pulseColorGreen} from '../../../types/pulse'

export default function useGetValueBandBarChartData() {
	const {getValueBandsChartData} = usePulseApi()
	const value = useApiValue(filterState, getValueBandsChartData, {})
	const coreCurrency = useRecoilValue(selectedCoreCurrencyState)

	const getOptions = () => {
		const options: ChartOptions<'bar'> = {
			indexAxis: 'x',
			plugins: {
				legend: {
					display: true,
					position: 'top'
				},
				tooltip: {
					bodySpacing: 8,
					boxPadding: 4,
					callbacks: {
						label: (tooltip: TooltipItem<'bar'>) => {
							if (tooltip.dataset.label === 'Conversion Rate') {
								return `${tooltip.dataset.label}: ${tooltip.raw}%`
							}

							return `${tooltip.dataset.label}: ${formatNumber(tooltip.raw?.toString() || '', 0)}`
						}
					},
					enabled: true,
					mode: 'index',
					padding: 16,
					titleMarginBottom: 8
				}
			},
			scales: {
				x: {
					display: true,
					grid: {
						display: false
					}
				},
				y1: {
					display: true,
					position: 'right',
					type: 'linear'
				},
				y2: {
					display: true,
					grid: {
						display: false
					},
					min: 0,
					position: 'left',
					ticks: {
						callback: function (value) {
							return `${value}%`
						}
					}
				}
			}
		}

		return options
	}

	const getLabels = () => {
		if (!value.data?.chartData) {
			return []
		}

		return value.data.chartData.map(band => {
			if (!band.rangeStart) {
				return `${formatCurrency(coreCurrency, 0)} - ${formatCurrency(coreCurrency, band.rangeEnd || 0)}`
			}

			if (!band.rangeEnd) {
				return `${formatCurrency(coreCurrency, band.rangeStart || 0)}+`
			}

			return `${formatCurrency(coreCurrency, band.rangeStart)} - ${formatCurrency(coreCurrency, band.rangeEnd)}`
		})
	}

	const getDatasets = () => {
		const datasets: ChartDataset<'bar'>[] = []

		if (!value.data?.chartData) {
			return datasets
		}

		const grossBookingVolumeData: number[] = []
		const conversionRateData: number[] = []

		for (let i = 0; i < value.data.chartData.length; i++) {
			const band = value.data.chartData[i]

			grossBookingVolumeData.push(band.grossBookingVolume || 0)
			conversionRateData.push(band.conversionRate || 0)
		}

		datasets.push({
			backgroundColor: pulseColorGray,
			data: grossBookingVolumeData,
			label: 'Gross Booking Volume',
			yAxisID: 'y1'
		}, {
			backgroundColor: pulseColorGreen,
			data: conversionRateData,
			label: 'Conversion Rate',
			yAxisID: 'y2'
		})

		return datasets
	}

	return {
		getDatasets,
		getLabels,
		getOptions,
		isLoading: value.isLoading
	}
}
